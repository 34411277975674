
import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios';

export default defineComponent({
  setup() {
      const backendLicenses = ref<unknown>(null)
      const frontendLicenses = ref<unknown>(null)
      const showSpinner = ref(false)
      const showError = ref(false)

      const fetchLicenses = () => {
        showSpinner.value = true
        axios.get('/api/thirdPartyLicenses').then(response => {
          // TODO sort by name (more important for frontend licenses)
          backendLicenses.value = response.data
          showError.value = false
          const cache_buster = {v: process.env.VUE_APP_PROJECT_VERSION}
          axios.get('/js/licenses.json', {params: cache_buster}).then(response => {
            // TODO sort by name (more important for frontend licenses)
            frontendLicenses.value = response.data
            showError.value = false
          })
          .catch(error => {
            console.log(error)
            showError.value = true
          })
        })
        .catch(error => {
          console.log(error)
          showError.value = true
        })
        .finally(() => {
          showSpinner.value = false
        })
      }

      onMounted(() => {
        fetchLicenses()
      })

      return { backendLicenses, frontendLicenses, showSpinner, showError }
  },
})

