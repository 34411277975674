import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42681d28"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "clients p-mt-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "pi pi-exclamation-triangle blink"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Panel = _resolveComponent("Panel")!
  const _component_PeriodicUpdate = _resolveComponent("PeriodicUpdate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PeriodicUpdate, {
      path: "/api/status",
      onUpdate: _ctx.onUpdate
    }, {
      default: _withCtx(() => [
        (_ctx.clients !== null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clients, (client) => {
                return (_openBlock(), _createBlock(_component_Panel, {
                  key: client.hwid,
                  header: 'aixtream-' + client.hwid,
                  class: "p-mt-2"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("h4", null, "Last seen: " + _toDisplayString(_ctx.formatPast(client.last_time)), 1),
                    _createElementVNode("h4", null, [
                      _createTextVNode("Next time: " + _toDisplayString(_ctx.formatRelative(client.next_time)) + " ", 1),
                      (_ctx.isNegative(client.next_time))
                        ? (_openBlock(), _createElementBlock("i", _hoisted_3))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("h4", null, "Deadline: " + _toDisplayString(_ctx.formatRelative(client.fail_deadline)), 1)
                  ]),
                  _: 2
                }, 1032, ["header"]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_Panel, {
                header: "Clients",
                class: "p-mb-2"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" No clients found. ")
                ]),
                _: 1
              })
            ]))
      ]),
      _: 1
    }, 8, ["onUpdate"])
  ]))
}