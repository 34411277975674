

import { defineComponent, ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router'
import axios from 'axios';
import Login from '@/components/Login.vue';

export default defineComponent({
  components: { Login },
  setup() {
      const router = useRouter()
      const showSpinner = ref(true)
      const isAuthenticated = ref(false)
      const version = process.env.VUE_APP_PROJECT_VERSION

      axios.interceptors.response.use(response => {
        return response
      }, error => {
        if (error.response && error.response.status === 401) {
          isAuthenticated.value = false
        }
        return Promise.reject(error);
      })

      const doLogout = () => {
        axios.get('/api/logout').then(() => {
          isAuthenticated.value = false
          router.push('/')
        })
        .catch(() => {
          isAuthenticated.value = false
        })
      }

      const menuItems = ref([
          {
              label: 'Status', 
              icon: 'pi pi-fw pi-home', 
              to: '/'
          },
          {
              label: 'Clients', 
              icon: 'pi pi-fw pi-list', 
              to: '/clients'
          },
          {
              label: 'Settings', 
              icon: 'pi pi-fw pi-pencil', 
              to: '/settings'
          },
          {
              label: 'Logout',
              icon: 'pi pi-fw pi-sign-out', 
              command: () => { doLogout() },
              class: 'logout-menu-item'
          }
      ]);

      const currentYear = new Date().getFullYear()

      onBeforeMount(() => {
        axios.get("/api/status").then(() => {
          isAuthenticated.value = true
        })
        .catch(() => {
          isAuthenticated.value = false
        })
        .finally(() => {
          showSpinner.value = false
        })
      })

      return { showSpinner, isAuthenticated, version, doLogout, menuItems, currentYear }
  }
})

