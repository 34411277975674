
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import axios from 'axios';

export default defineComponent({
  props: {
    path: {
      type: String,
      required: true
    }
  },
  emits: ['update', 'error'],
  setup(props, context) {
      // we use setTimeout because setInterval does not take into account the execution time of the callback
      let timer: number | null
      let isMounted = false
      const progress = ref(0)
      const showSpinner = ref(false)
      const showError = ref(false)

      const update = () => {
        showSpinner.value = true
        axios.get(props.path).then(response => {
          context.emit('update', response)
          showError.value = false
        })
        .catch(error => {
          console.log(error)
          context.emit('error', error)
          showError.value = true
        })
        .finally(() => {
          showSpinner.value = false
          if (isMounted) {
            timer = setTimeout(update, process.env.VUE_APP_POLL_INTERVAL)
          }
        })
      }

      onMounted(() => {
        isMounted = true
        update()
      })

      onBeforeUnmount(() => {
        isMounted = false
        if (timer) {
          clearTimeout(timer)
          timer = null
        }
        showSpinner.value = false
        showError.value = false
      })

      return {progress, showSpinner, showError}
  },
})
