

import { defineComponent, ref, onBeforeMount } from 'vue';
import axios from 'axios';
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";

export default defineComponent({
  props: {
    isAuthenticated: Boolean
  },
  emits: ['update:isAuthenticated'],
  setup(props, context) {
    const toast = useToast();
    const confirm = useConfirm();
    const waitForResponse = ref(true)
    const validResponse = ref(false)
    const rootPassword = ref<string | null>(null)
    const rootPasswordRepeat = ref<string | null>(null)
    const isRootPasswordRepeatValid = ref(true)
    const statusPassword = ref<string | null>(null)
    const statusPasswordRepeat = ref<string | null>(null)
    const isStatusPasswordRepeatValid = ref(true)
    const clientSecret = ref<string | null>(null)
    const sessionTimeout = ref<number | null>(null)
    const logLevel = ref<string | null>(null)

    const logLevels = ref([
      {label: 'Debug', code: 'debug'},
      {label: 'Info', code: 'info'},
      {label: 'Warning', code: 'warning'},
      {label: 'Error', code: 'error'}
    ]);

    const showErrorToast = (error: string) => {
      toast.add({severity: 'error', summary: 'Error', detail: error, life: 3000})
    }

    const showSuccessToast = (message: string) => {
      toast.add({severity: 'success', summary: 'Success', detail: message, life: 3000})
    }

    const resetPasswords = () => {
      rootPassword.value = null
      rootPasswordRepeat.value = null
      isRootPasswordRepeatValid.value = true
      statusPassword.value = null
      statusPasswordRepeat.value = null
      isStatusPasswordRepeatValid.value = true
    };

    const getSettings = () => {
      waitForResponse.value = true
      resetPasswords()
      axios.get('/api/settings').then(response => {
        clientSecret.value = response.data.client.secret
        sessionTimeout.value = response.data.http.session_timeout
        logLevel.value = response.data.log.level
        validResponse.value = true
      })
      .catch(() => {
        clientSecret.value = null
        sessionTimeout.value = null
        logLevel.value = null
        validResponse.value = false
        showErrorToast("Could not read settings from backend server.")
      })
      .finally(() => {
        new Promise(resolve => setTimeout(resolve, 100)).then(() => {
          // NOTE workaround for possible BlockUI bug
          waitForResponse.value = false
        })
      })
    }

    const validatePasswords = () => {
      isRootPasswordRepeatValid.value = !rootPassword.value ||
                                        (rootPassword.value.length >= 6 &&
                                        rootPassword.value === rootPasswordRepeat.value)
      isStatusPasswordRepeatValid.value = !statusPassword.value ||
                                          (statusPassword.value.length >= 6 &&
                                          statusPassword.value === statusPasswordRepeat.value)
      return isRootPasswordRepeatValid.value && isStatusPasswordRepeatValid.value
    };

    const saveSettings = () => {
      if (!validatePasswords()) {
        showErrorToast("Passwords are not valid or don't match.")
        return
      }
      waitForResponse.value = true
      let settings = {
        client: {
          secret: clientSecret.value
        },
        http: {
          session_timeout: sessionTimeout.value
        },
        log: {
          level: logLevel.value
        }
      }
      if (rootPassword.value)
          Object.assign(settings, {auth: {root_password: rootPassword.value}})
      if (statusPassword.value)
          Object.assign(settings, {auth: {status_password: statusPassword.value}})
      resetPasswords()
      axios.post('/api/settings', settings).then(() => {
        showSuccessToast("Settings saved.")
      })
      .catch(() => {
        showErrorToast("Could not save settings.")
        //logLevel.value = null
      })
      .finally(() => {
        getSettings()
      })
    };

    const restartBackend = () => {
      confirm.require({
        message: 'Are you sure you want to restart the license server?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          axios.post('/api/restart').then(() => {
          // TODO (in finally) wait for restart and show spinner
          })
        }
      });
    };

    const onFrnUpload = () => {
      showSuccessToast('License file uploaded successfully.');
    }

    const onFrnError = (event: any) => {
      if (event.xhr.status === 401)
        context.emit('update:isAuthenticated', false) //props.isAuthenticated.value = false
      let error = event.xhr.responseText
      if (error.length === 0)
        error = event.xhr.statusText
      showErrorToast('License file uploaded failed: ' + error)
    }


    onBeforeMount(() => {
      getSettings()
    })

    return { waitForResponse, validResponse, rootPassword, rootPasswordRepeat, isRootPasswordRepeatValid, statusPassword, statusPasswordRepeat, isStatusPasswordRepeatValid, clientSecret, sessionTimeout, logLevel, logLevels, getSettings, saveSettings, restartBackend, onFrnUpload, onFrnError }
  }
})

