import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-255afcd5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_Message = _resolveComponent("Message")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BlockUI, { blocked: _ctx.showError }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["blocked"]),
    (_ctx.showError)
      ? (_openBlock(), _createBlock(_component_Message, {
          key: 0,
          severity: "error",
          closable: false
        }, {
          default: _withCtx(() => [
            _createTextVNode("No connection to backend server")
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showSpinner)
        ? (_openBlock(), _createBlock(_component_ProgressBar, {
            key: 0,
            mode: "indeterminate",
            class: "progress"
          }))
        : (_openBlock(), _createBlock(_component_ProgressBar, {
            key: 1,
            mode: "determinate",
            value: 0,
            showValue: false,
            class: "progress"
          }))
    ])
  ], 64))
}