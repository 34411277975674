import { createApp } from 'vue'
import App from './App.vue'

import axios from 'axios'

import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import ProgressBar from 'primevue/progressbar';
import BlockUI from 'primevue/blockui';
import TabMenu from 'primevue/tabmenu';
import Panel from 'primevue/panel';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import FileUpload from 'primevue/fileupload';
import Tooltip from 'primevue/tooltip';

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import router from './router'

import PrettyDurationPlugin from './plugins/pretty-duration';
import PeriodicUpdate from './components/PeriodicUpdate.vue';

axios.defaults.timeout = process.env.VUE_APP_API_TIMEOUT
const app = createApp(App).use(router)

app.use(PrimeVue, {ripple: true})
app.use(ToastService)
app.use(ConfirmationService)
app.use(PrettyDurationPlugin)

app.component('Button', Button)
app.component('Toast', Toast)
app.component('Message', Message)
app.component('ProgressSpinner', ProgressSpinner)
app.component('ProgressBar', ProgressBar)
app.component('Panel', Panel)
app.component('BlockUI', BlockUI)
app.component('TabMenu', TabMenu)
app.component('InputText', InputText)
app.component('InputNumber', InputNumber)
app.component('Password', Password)
app.component('Dropdown', Dropdown)
app.component('ConfirmDialog', ConfirmDialog)
app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('FileUpload', FileUpload)
app.directive('tooltip', Tooltip)

app.component('PeriodicUpdate', PeriodicUpdate)

app.mount('#app')
