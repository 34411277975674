import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "thirdPartyLicenses p-mt-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_Message = _resolveComponent("Message")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Panel, {
      header: "Backend",
      class: "p-mb-2"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Accordion, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.backendLicenses, (license) => {
              return (_openBlock(), _createBlock(_component_AccordionTab, {
                key: license.name,
                header: license.name
              }, {
                default: _withCtx(() => [
                  (license.description)
                    ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(license.description), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("h4", null, "Homepage: " + _toDisplayString(license.homepage), 1),
                  _createElementVNode("h4", null, "Version: " + _toDisplayString(license.version), 1),
                  _createElementVNode("h4", null, "License: " + _toDisplayString(license.license), 1),
                  _createElementVNode("pre", null, _toDisplayString(license.licenseText), 1)
                ]),
                _: 2
              }, 1032, ["header"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_Panel, { header: "Frontend" }, {
      default: _withCtx(() => [
        _createVNode(_component_Accordion, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.frontendLicenses, (license) => {
              return (_openBlock(), _createBlock(_component_AccordionTab, {
                key: license.name,
                header: license.name
              }, {
                default: _withCtx(() => [
                  (license.description)
                    ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(license.description), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("h4", null, "Homepage: " + _toDisplayString(license.homepage), 1),
                  _createElementVNode("h4", null, "Version: " + _toDisplayString(license.version), 1),
                  _createElementVNode("h4", null, "License: " + _toDisplayString(license.license), 1),
                  _createElementVNode("pre", null, _toDisplayString(license.licenseText), 1)
                ]),
                _: 2
              }, 1032, ["header"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.showError)
      ? (_openBlock(), _createBlock(_component_Message, {
          key: 0,
          severity: "error",
          closable: false
        }, {
          default: _withCtx(() => [
            _createTextVNode("No connection to backend server")
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_ProgressSpinner)
    ], 512), [
      [_vShow, _ctx.showSpinner]
    ])
  ]))
}