

import { defineComponent, ref, inject } from 'vue';

export default defineComponent({
  setup() {
    type PrettyDurationType = (seconds: number | null) => string;
    const prettyDuration = inject('prettyDuration') as PrettyDurationType
    const clients = ref<Array<any> | null>(null)

    const onUpdate = (response: any) => {
      if (response.data.clients && response.data.clients.length) {
          clients.value = response.data.clients
      } else {
          clients.value = null
      }
    }

    const formatPast = (seconds: number | null | undefined): string => {
      if (seconds === null || seconds === undefined) {
        return prettyDuration(null)
      } else {
        return prettyDuration(seconds) + " ago"
      }
    }

    const formatRelative = (seconds: number | null | undefined): string => {
      if (seconds === null || seconds === undefined) {
        return prettyDuration(null)
      } else if (seconds >= 0) {
        return "in " + prettyDuration(seconds)
      } else {
        return prettyDuration(-seconds) + " overdue"
      }
    }

    const isNegative = (seconds: number | null | undefined): boolean => {
      if (seconds === null || seconds === undefined || seconds >= 0)
        return false
      return true
    }

    return { clients, onUpdate, formatPast, formatRelative, isNegative }
  },
})

