

import { defineComponent, ref, inject } from 'vue';

export default defineComponent({
  setup() {
    const prettyDuration = inject('prettyDuration')
    const hwid = ref<string | null>(null)
    const hwid_ok = ref(false)
    const uptime = ref<number | null>(null)
    const clients = ref<Array<any> | null>(null)
    const clients_error = ref<boolean>(false)

    const prettyClients = () => {
      if (clients.value === null) {
        return 'not available'
      }
      const clients_total = clients.value.length
      let clients_ok = 0
      let error = false
      for (let client of clients.value) {
        if (client.next_time !== undefined) {
          if (client.next_time >= 0) {
            clients_ok++
          } else {
            error = true
          }
        }
      }
      clients_error.value = error
      return String(clients_ok) + ' / ' + String(clients_total)
    }

    const onUpdate = (response: any) => {
      hwid_ok.value = response.data.hwid_ok
      if (response.data.hwid) {
        hwid.value = response.data.hwid
      } else {
        hwid.value = null
      }
      uptime.value = response.data.uptime
      if (response.data.clients && response.data.clients.length) {
          clients.value = response.data.clients
      } else {
          clients.value = null
      }
    }

    return { prettyDuration, hwid, hwid_ok, uptime, clients, clients_error, prettyClients, onUpdate }
  },
})

