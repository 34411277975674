import { App } from 'vue';

export default {
  install: (app: App): void => {
    function prettyDuration(seconds: number | null): string {
      if (seconds === null)
        return "not available"
      const years = Math.floor(seconds/(60*60*24*365))
      let remainder = seconds-(years*(60*60*24*365))
      const days = Math.floor(remainder/(60*60*24))
      remainder -= days*(60*60*24)
      const hours = Math.floor(remainder/(60*60))
      remainder -= hours*(60*60)
      const minutes = Math.floor(remainder/60)
      remainder -= minutes*60
      let res = ""
      if (years) {
        res += years + " year"
        if (years > 1)
          res += "s"
        res += " "
      }
      if (days) {
        res += days + " day"
        if (days > 1)
          res += "s"
        res += " "
      }
      if (hours) {
        res += hours + " hour"
        if (hours > 1)
          res += "s"
        res += " "
      }
      if (minutes) {
        res += minutes + " minute"
        if (minutes > 1)
          res += "s"
        res += " "
      }
      if (remainder) {
        res += remainder + " second"
        if (remainder > 1)
          res += "s"
      }
      if (res === "")
        return "0 seconds"
      return res.trim()
    }

    app.config.globalProperties.$prettyDuration = prettyDuration;
    app.provide('prettyDuration', prettyDuration);
  }
}
