import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Status from '../views/Status.vue'
import Settings from '../views/Settings.vue'
import Clients from '../views/Clients.vue'
import ThirdPartyLicenses from '../views/ThirdPartyLicenses.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Status',
    component: Status
  },
  {
    path: '/clients',
    name: 'Clients',
    component: Clients
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/thirdPartyLicenses',
    name: 'ThirdPartyLicenses',
    component: ThirdPartyLicenses
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
