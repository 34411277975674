

import { defineComponent, ref } from 'vue';
import axios from 'axios';
import { useToast } from 'primevue/usetoast';

export default defineComponent({
  props: {
    isAuthenticated: Boolean
  },
  emits: ['update:isAuthenticated'],
  setup(props, context) {
    const toast = useToast();
    const user = ref("root")
    const password = ref("")
    const showSpinner = ref(false)

    const showErrorToast = (error: string) => {
      toast.add({severity: 'error', summary: 'Login failed', detail: error, life: 3000})
    }

    const doLogin = () => {
      axios.post('/api/login', {'user': user.value, 'password': password.value})
      .then(() => {
        context.emit('update:isAuthenticated', true) //props.isAuthenticated.value = true
      })
      .catch(err => {
        console.log(err)
        showErrorToast(err.message)
        context.emit('update:isAuthenticated', false) //props.isAuthenticated.value = false
      })
    }

    return { user, password, showSpinner, showErrorToast, doLogin }
  }
})

